import * as echarts from "echarts";

export function formatterHS(value: number): string {
  if (value > 1e15) {
    return "" + Math.floor(value / 1e15) + " PH/s";
  } else if (value > 1e12) {
    return "" + Math.floor(value / 1e12) + " TH/s";
  } else if (value > 1e9) {
    return "" + Math.floor(value / 1e9) + " GH/s";
  } else if (value > 1e6) {
    return "" + Math.floor(value / 1e6) + " MH/s";
  } else if (value > 1e3) {
    return "" + Math.floor(value / 1e3) + " KH/s";
  }
  return "" + value + " H/s";
}

export function emptyIncome() {
  return { data: {} };
}

export function emptyPaid() {
  return emptyIncome();
}

export function transpose(array: any): any {
  return array[0].map((col: any, i: number) => array.map((row: any) => row[i]));
}

export function ooa2aoo(obj: any): any {
  const arr = transpose(Object.values(obj));
  const keys = Object.keys(obj);
  return arr.map((row: any) => Object.fromEntries(transpose([keys, row])));
}

// [
//   [time, val],
//   [time, val],
// ]
export function create30minData(times: number[], values: number[]): number[][] {
  const m30 = 30 * 60 * 1000;
  const min_date: number =
    new Date(Date.now() - 24 * 60 * 60 * 1000).getTime() + m30;
  const max_date: number = Date.now() - 3 * m30;
  let ret: number[][] = times.map(function (e: number, i: number) {
    return [e * 1000, values[i]];
  });

  // add xMin
  if (ret[0][0] > min_date) {
    ret.unshift([ret[0][0] - m30, 0]);
  }

  // add xMax
  if (ret[ret.length - 1][0] < max_date) {
    ret.push([ret[ret.length - 1][0] + m30, 0]);
  }

  // cut xMax
  if (ret[ret.length - 1][0] > Date.now() - m30 * 1.5) {
    ret.pop();
  }

  return ret;
}

export function createLongData(times: number[], values: number[]): number[][] {
  let ret: number[][] = times.map((e: number, i: number) => {
    return [e * 1000, values[i]];
  });
  const max_time: number = new Date(Date.now() - 24 * 60 * 60 * 1000).getTime();

  // cut xMin
  ret = ret.filter((v: number[]) => {
    return v[0] < max_time;
  });

  return ret;
}

export function mergeArray(arr1: number[][], arr2: number[][]): number[][] {
  let ret = [...arr1, ...arr2];
  return ret.sort((a, b) => a[0] - b[0]);
}

export function sumArray(arr: number[][]): number[][] {
  let ret: number[][] = [];
  arr.forEach((v: number[]) => {
    if (ret.length > 0) {
      let last = ret[ret.length - 1][0];
      if (v[0] === last) {
        ret[ret.length - 1][1] = v[1] + ret[ret.length - 1][1];
        return;
      }
    }
    ret.push(v);
  });
  return ret;
}

export function integral(
  x0: number,
  x1: number,
  y0: number,
  y1: number,
  t0: number,
  t1: number
): number {
  const a: number = (y1 - y0) / (x1 - x0);
  const square: number = (t1 - t0) * y0;
  const triangle: number = 0.5 * a * (t1 - t0) * (t1 - t0);
  return square + triangle;
}

export function integralOverTime(
  arr: number[][],
  t0: number,
  t1: number
): number {
  let sum = 0;
  if (arr.length !== 0) {
    arr.reduce((acc, val, _) => {
      const x0 = acc[0];
      const x1 = val[0];
      const y0 = acc[1];
      const y1 = val[1];
      if (x1 < t0 || t1 < x0) {
        // out of range
      } else {
        let t0_ = Math.max(x0, t0);
        let t1_ = Math.min(x1, t1);
        sum += integral(x0, x1, y0, y1, t0_, t1_);
      }
      return val;
    });
  }
  return sum;
}

export function meanOverTime(arr: number[][], t0: number, t1: number): number {
  return integralOverTime(arr, t0, t1) / (t1 - t0);
}
