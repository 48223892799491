import React, { useState } from "react";
import {
  Routes,
  Route,
  Outlet,
  Link,
  useRoutes,
  useParams,
} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import { db } from "./firebase";
import * as firestore from "firebase/firestore";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import {
  formatterHS,
  emptyIncome,
  emptyPaid,
  ooa2aoo,
  create30minData,
  createLongData,
  mergeArray,
  sumArray,
  meanOverTime,
} from "./Graph";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FormControl } from "react-bootstrap";
import moment, { Moment } from "moment-timezone";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";

export function prev30min(time: number): number {
  return Math.floor(time / 1800000) * 1800000;
}

export function next30min(time: number): number {
  return prev30min(time) + 1800000;
}

function App() {
  return (
    <div className="space-y-5 mx-5">
      <h2 className="border-b">Kadena pool</h2>
      <Routes>
        <Route path="/" element={<AppImpl />}>
          <Route path="w" element={<Wallet />}>
            <Route path=":id" element={<WalletImpl type="wallets" />} />
          </Route>
          <Route path="u" element={<Wallet />}>
            <Route path=":id" element={<WalletImpl type="users" />} />
          </Route>
          <Route path="d/GCh0CVkVk" element={<Wallet />}>
            <Route path=":id" element={<WalletImpl type="wallets" />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function AppImpl() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function Wallet() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return <h2> Please specify wallet id.</h2>;
}

type Range = {
  start: Moment;
  end: Moment;
};

type GraphProps = {
  hashrates: number[][];
  counts: number[][];
  range: Range;
};

const GraphSection: React.VFC<GraphProps> = (props) => {
  //const [optionHashrate, setOptionHashrate] = useState(emptyHashrateOption());
  //const [optionCount, setOptionCount] = useState(emptyCountOption());

  // count
  const optionCount = {
    xAxis: {
      type: "time",
      axisLabel: {
        formatter: function (value: any) {
          return echarts.time.format(value, "{yy}/{MM}/{dd} {HH}:{mm}", false);
        },
        rotate: 90,
      },
      min: props.range.start.valueOf(),
      max: props.range.end.valueOf(),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        margin: -20,
        inside: true,
      },
    },
    series: [
      {
        data: props.counts,
        type: "line",
        showSymbol: false,
        smooth: true,
      },
    ],
    tooltip: {
      show: true,
      trigger: "axis",
    },
  };
  console.log(optionCount);

  // hashrate
  const optionHashrate = {
    ...optionCount,
    yAxis: {
      type: "value",
      axisLabel: {
        inside: true,
        margin: -20,
        formatter: formatterHS,
      },
    },
    series: [
      {
        data: props.hashrates,
        type: "line",
        showSymbol: false,
        smooth: true,
      },
    ],
    tooltip: {
      show: true,
      trigger: "axis",
      valueFormatter: formatterHS,
    },
  };
  console.log(optionHashrate);

  const end =
    props.hashrates.length > 0
      ? Math.min(
          props.range.end.valueOf(),
          props.hashrates[props.hashrates.length - 1][1]
        )
      : props.range.end.valueOf();
  const avgHashrate = formatterHS(
    meanOverTime(props.hashrates, props.range.start.valueOf(), end)
  );

  return (
    <div>
      <div className="p-3 bg-white shadow rounded-lg">
        <h3 className="border-b"> Hashrate </h3>
        <div className="text-right">Average Hashrate: {avgHashrate}</div>
        <ReactEcharts option={optionHashrate} />
      </div>
      <div className="p-3 bg-white shadow rounded-lg">
        <h3 className="border-b"> Count </h3>
        <ReactEcharts option={optionCount} />
      </div>
    </div>
  );
};

type PayoutTableProps = {
  type: string;
  option: any;
};

type PayoutType = {
  time: number;
  amounts: number;
};

const PayoutTable: React.VFC<PayoutTableProps> = (props) => {
  const data = (() => {
    if (props.type === "") {
      return [];
    } else {
      return props.option.data as [PayoutType];
    }
  })();
  const columnHelper = createColumnHelper<PayoutType>();
  const columns = [
    columnHelper.accessor("time", {
      header: "Time",
      cell: (info) =>
        echarts.time.format(
          new Date(info.getValue() * 1000),
          "{yyyy}/{MM}/{dd} {HH}:{mm}",
          false
        ),
    }),
    columnHelper.accessor("amounts", {
      cell: (info) => info.getValue(),
      header: "Amount",
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (props.type === "") {
    return <div />;
  } else {
    return (
      <div className="p-3 bg-white shadow rounded-lg">
        <h3 className="border-b">{props.type} during recent 30 days</h3>

        <div className="mt-4 -mb-3">
          <div className="not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
            <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"></div>
            <div className="relative rounded-xl overflow-auto">
              <div className="shadow-sm overflow-hidden my-8">
                <table className="table-auto w-full border-collapse">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id} className="border-b">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="border-b">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

type WalletImplProps = {
  type: string;
};

const WalletImpl: React.VFC<WalletImplProps> = ({ type }) => {
  let { id } = useParams<"id">();

  const [optionHashrates, setOptionHashRates] = useState([]);
  const [optionCounts, setOptionCounts] = useState([]);
  const [optionIncome, setOptionIncome] = useState(emptyIncome());
  const [optionPaid, setOptionPaid] = useState(emptyPaid());

  const accessHashrates = async () => {
    let hashrates, counts;
    const mergeArraySum = (arr1, arr2) => sumArray(mergeArray(arr1, arr2));

    {
      console.log("collection hashrates start");

      let path = type + "/" + id + "/hashrates";
      const querySnapshot = await firestore.getDocs(
        firestore.collection(db, path)
      );

      querySnapshot.docs.forEach((doc: any) => {
        // TODO: multiple sites
        const data = doc.data();
        console.log(doc.id, " => ", data);

        hashrates = create30minData(data.time, data.hashrates);
        counts = create30minData(data.time, data.counts);
      });
      console.log("collection hashrates done");
    }
    {
      console.log("collection longhashrates start");

      let path = type + "/" + id + "/longhashrates";
      const querySnapshot = await firestore.getDocs(
        firestore.collection(db, path)
      );

      querySnapshot.docs.forEach((doc: any) => {
        // TODO: multiple sites
        const data = doc.data();
        console.log(doc.id, " => ", data);

        hashrates = mergeArraySum(
          hashrates,
          createLongData(data.time, data.hashrates)
        );
        counts = mergeArraySum(counts, createLongData(data.time, data.counts));
      });
      console.log("collection longhashrates done");
    }

    setOptionHashRates(hashrates);
    setOptionCounts(counts);
  };

  const accessIncome = async (type2: string, setOption) => {
    console.log("collection " + type2 + "start");
    const path = type + "/" + id + "/" + type2;
    const querySnapshot = await firestore.getDocs(
      firestore.collection(db, path)
    );
    querySnapshot.docs.forEach((doc: any) => {
      // TODO: multiple sites
      const data = doc.data();
      console.log(doc.id, " => ", data);
      const option = {
        data: ooa2aoo(data),
      };
      setOption(option);
    });
    console.log("collection " + type2 + " done");
  };

  React.useEffect(() => {
    const accessDb = async () => {
      accessHashrates();
      accessIncome("income", setOptionIncome);
      accessIncome("paid", setOptionPaid);
    };
    accessDb();
  }, []);

  // Datetime picker
  const applyCallback = (startDate, endDate) => {
    console.log("Apply Callback");
    console.log(startDate.format("DD-MM-YYYY HH:mm"));
    console.log(endDate.format("DD-MM-YYYY HH:mm"));
    setStateRange({
      start: startDate,
      end: endDate,
    });
  };

  const now = new Date();
  const defaultEnd = moment(now).subtract(30, "minutes");
  const defaultStart = moment(defaultEnd).subtract(24, "hours");
  const [stateRange, setStateRange] = useState({
    start: defaultStart,
    end: defaultEnd,
  });

  const today = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  const today_end = moment(today).add(1, "days").subtract(1, "seconds");
  const ranges = {
    Yesterday: [
      moment(today).subtract(1, "days"),
      moment(today_end).subtract(1, "days"),
    ],
  };
  const local = {
    format: "YYYY/MM/DD HH:mm",
    sundayFirst: true,
  };

  let rangeText = `${stateRange.start.format(
    "YYYY/MM/DD HH:mm"
  )} - ${stateRange.end.format("YYYY/MM/DD HH:mm")}`;

  return (
    <div>
      <p className="text-sm truncate">{id}</p>
      <DateTimeRangeContainer
        ranges={ranges}
        start={stateRange.start}
        end={stateRange.end}
        maxDate={moment(now)}
        local={local}
        applyCallback={applyCallback}
        smartMode
      >
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>

        <FormControl
          id="formControlsTextB"
          type="text"
          label="Text"
          placeholder="Enter text"
          style={{ cursor: "pointer" }}
          disabled="true"
          value={rangeText}
          className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </DateTimeRangeContainer>
      <GraphSection
        hashrates={optionHashrates}
        counts={optionCounts}
        range={stateRange}
      />
      <PayoutTable
        type={type === "wallets" ? "" : "Income"}
        option={optionIncome}
      />
      <PayoutTable
        type={type === "wallets" ? "" : "Paid"}
        option={optionPaid}
      />
    </div>
  );
};

export default App;
